// pages/aboutus/aboutus.jsx
import React from "react";

function AboutUs() {
  return (
    <div className="bg-white py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-md px-4 md:px-8">
      <h1 className="mb-4 text-center text-2xl font-bold text-red-950 sm:text-3xl md:mb-6">
  Grapcodes: Crafting Creative Solutions
</h1>


        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          Grapcodes is a vibrant and innovative creative studio, driven by a passion for bringing visions to life through captivating designs. Established with a clear mission to elevate brands through compelling visuals, we specialize in crafting bespoke logos, graphics, and emotes that resonate deeply with our clients' identities and their target audiences. Our journey began with a commitment to excellence and a relentless pursuit of innovation, and today, we stand as a beacon of creativity in the competitive landscape of design.
        </p>

        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          At Grapcodes, our team is the heart and soul of our operation. Comprised of skilled designers, each with their own unique blend of talent and expertise, we bring together a diverse range of creative perspectives to every project we undertake. From seasoned professionals to fresh talents bursting with creativity, our team collaborates seamlessly to deliver exceptional results that exceed expectations. We firmly believe that the synergy of our collective creativity is what sets us apart and allows us to consistently produce work of the highest caliber.
        </p>

        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          What sets Grapcodes apart is our unwavering dedication to understanding our clients' needs and aspirations. We approach each project with a keen sense of curiosity, taking the time to delve deep into the essence of their brand, their values, and their goals. By immersing ourselves in their world, we gain valuable insights that inform every aspect of our design process, ensuring that the end result is not just visually stunning but also deeply meaningful and impactful.
        </p>

        <div className="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8">
          <img
            src="asset/real (2).jpeg"
            loading="lazy"
            alt="Photo by Minh Pham"
            className="h-full w-full object-cover object-center"
          />
        </div>

        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          Whether our clients are startups seeking to establish their identity or established brands looking to refresh their image, Grapcodes is here to turn their ideas into visually stunning realities. We thrive on the challenge of transforming concepts into tangible expressions that leave a lasting impression. From conceptualization to execution, we guide our clients every step of the way, providing them with the creative expertise and support they need to achieve their goals.
        </p>

        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          At the core of everything we do is a steadfast commitment to quality, professionalism, and client satisfaction. We believe that the true measure of our success lies in the success of our clients, and we go above and beyond to ensure that every project we undertake is executed with the utmost care and attention to detail. From the initial consultation to the final deliverable, we strive to exceed expectations at every turn, earning the trust and loyalty of our clients along the way.
        </p>

        <p className="mb-6 text-gray-500  text-left sm:text-lg md:mb-8">
          In today's fast-paced and ever-evolving market, the importance of a strong visual presence cannot be overstated. With Grapcodes as your creative partner, you can rest assured that your brand will stand out from the crowd and make a lasting impression on your audience. So why settle for ordinary when you can have extraordinary? Trust Grapcodes to elevate your brand to new heights and unleash its full potential in the world of design.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
