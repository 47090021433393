import React, { useState } from "react";

function Logo() {
  const [showMore, setShowMore] = useState(false);

  const additionalImages = [
    {
      imageUrl: "asset/image5.jpeg",
    },
    {
      imageUrl: "asset/image6.jpeg",
    },

    {
      imageUrl: "asset/image7.jpeg",
    },
    {
      imageUrl: "asset/image8.jpeg",
    },
    {
      imageUrl: "asset/image9.jpeg",
    },
    {
      imageUrl: "asset/image10.jpeg",
    },
     {imageUrl: "asset/img-2.png",
    },
    {imageUrl: "asset/img-3.png",
  },
  {imageUrl: "asset/img-4.png",
},
{imageUrl: "asset/img-5.png",
},
{imageUrl: "asset/img-6.png",
},
{imageUrl: "asset/img-7.png",
},
{imageUrl: "asset/img-8.png",
},
{imageUrl: "asset/img-9.png",
},
{imageUrl: "asset/img-22.png",
},
{imageUrl: "asset/img-23.png",
},
{imageUrl: "asset/img-24.png",
},
{imageUrl: "asset/img-25.png",
},
{imageUrl: "asset/img-26.png",
},
{imageUrl: "asset/img-27.png",
},
{imageUrl: "asset/img-28.png",
},
{imageUrl: "asset/img-29.png",
},
{imageUrl: "asset/img-30.png",
},
{imageUrl: "asset/img-32.png",
},
{imageUrl: "asset/img-34.png",
},
{imageUrl: "asset/img-35.png",
},
{imageUrl: "asset/img-36.png",
},
{imageUrl: "asset/img-37.png",
},
{imageUrl: "asset/img-38.png",
},
{imageUrl: "asset/img-39.jpeg",
},
{imageUrl: "asset/img-40.jpeg",
},
{imageUrl: "asset/img-42.jpeg",
},
{imageUrl: "asset/img-43.jpeg",
},
{imageUrl: "asset/img-44.jpeg",
},
{imageUrl: "asset/img-45.jpeg",
},
{imageUrl: "asset/img-46.jpeg",
},





    // Add more images as needed
  ];

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className=" mb-7 ">
      <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
        {/* Display initial images */}
        <div>
          <a
            href="#"
            className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
          >
            <img
              src="asset/image4.jpeg"
              loading="lazy"
              alt="Photo by Austin Wade"
              className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />
          </a>

          <div className="flex flex-col"></div>
        </div>
        <div>
          <a
            href="#"
            className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
          >
            <img
              src="asset/image2.jpeg"
              loading="lazy"
              alt="Photo by Austin Wade"
              className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />
          </a>

          <div className="flex flex-col"></div>
        </div>{" "}
        <div>
          <a
            href="#"
            className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
          >
            <img
              src="asset/img-47.jpeg"
              loading="lazy"
              alt="Photo by Austin Wade"
              className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />
          </a>

          <div className="flex flex-col"></div>
        </div>{" "}
        <div>
          <a
            href="#"
            className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
          >
            <img
              src="asset/image1.jpeg"
              loading="lazy"
              alt="Photo by Austin Wade"
              className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />
          </a>

          <div className="flex flex-col"></div>
        </div>
        {/* Repeat this structure for the other initial images */}
        {/* Conditionally render additional images when showMore is true */}
        {showMore &&
          additionalImages.map((image, index) => (
            <div key={index}>
              <a
                href="#"
                className="group mb-2 block h-96 overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:mb-3"
              >
                <img
                  src={image.imageUrl}
                  loading="lazy"
                  alt={image.alt}
                  className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                />
              </a>

              <div className="flex flex-col">
                <span className="text-gray-500">{image.category}</span>
                <a
                  href="#"
                  className="text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl"
                >
                  {image.title}
                </a>
              </div>
            </div>
          ))}
      </div>

      {/* Show more button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handleShowMore}
          className="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
        >
          {showMore ? "Show less" : "Show more"}
        </button>
      </div>
    </div>
  );
}

export default Logo;
