import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Data } from "./data";

function MainCarousel() {
  const items = Data.map((item, index) => (
    <div key={index} className="carousel-item">
      <img
        className="carousel-image cursor-pointer rounded-full shadow-2xl mb-4 mx-auto"
        src={item.image}
        alt={item.name}
      />
      <div className="text-center">
        <h5 className="text-lg text-red-950  font-semibold mb-2">{item.name}</h5>
        <p>{item.comment}</p>
      </div>
    </div>
  ));

  return (
    <AliceCarousel
      items={items}
      disableButtonsControls
      autoPlay
      autoPlayInterval={1000}
      infinite
    />
  );
}

export default MainCarousel;
