import React from "react";
import Hero from "./../../components/hero/hero";

import Content from "./../../components/content/content";

import Testimonials from "./../../components/Testimonials/Testimonials";
import Collection from "./../../components/collection/collection";

function home() {
  return (
    <div className="">
      <Hero />
      <Collection />
        <Content />
      <Testimonials />
    </div>
  );
}

export default home;
