import React from "react";

function Hero() {
  return (
    <div className="bg-white py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <div className="flex flex-col-reverse md:flex-row items-center justify-between">
          {/* Content Section */}
          <div className="md:w-1/2 lg:w-5/12">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-red-950 mb-6 sm:mb-8 lg:mb-10 xl:mb-12">
              Elevate Your Brand Identity
            </h1>
            <p className="text-lg lg:text-xl text-gray-500 leading-relaxed mb-8 xl:mb-10">
              Enhance your brand identity with Grapcodes Design's top-tier
              graphic design services. Our award-winning designers craft logos,
              branding materials, and websites for brands big and small,
              ensuring your business stands out from the crowd.
            </p>
            {/* Add a button here if needed */}
          </div>

          {/* Image Section */}
          <div className="md:w-1/2 lg:w-7/12 mt-8 md:mt-0 relative">
            <div className="overflow-hidden rounded-lg shadow-xl">
              <img
                src="asset/real (4).jpeg"
                loading="lazy"
                alt="Team collaboration"
                className="w-full object-cover object-center transform transition-transform duration-300 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
