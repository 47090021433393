import React from "react";

function Revamz() {
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="lg:w-1/2 w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className=" text-left title-font sm:text-4xl text-5xl mb-4 font-medium text-gray-900">
              Welcome to Kindle Book <br />
              Services
            </h1>
            <p className=" text-left mb-8 leading-relaxed text-lg">
              Self-publish print and digital books, and reach millions of
              readers around the world on Amazon.
            </p>
            <div className="mb-16 ">
              <h2 className="mb-4 text-3xl font-medium text-gray-900">
                Reviews
              </h2>
              <ul className="list-disc ml-8 text-left">
                <li className="mb-2">Gain valuable feedback from readers.</li>
                <li className="mb-2">
                  Understand what your audience likes and dislikes about your
                  work.
                </li>
                <li className="mb-2">
                  Improve your books based on constructive criticism.
                </li>
                <li className="mb-2">
                  Enhance your credibility as an author with positive reviews.
                </li>
              </ul>
            </div>
            <div className="mb-16 ">
              <h2 className="mb-4 text-3xl font-medium text-gray-900">
                Rating
              </h2>
              <ul className="list-disc ml-8 text-left">
                <li className="mb-2">
                  Display the average rating of your book prominently.
                </li>
                <li className="mb-2">
                  Attract more readers by showcasing positive feedback.
                </li>
                <li className="mb-2">
                  Increase the perceived quality of your book.
                </li>
                <li className="mb-2">
                  Encourage potential readers to give your book a chance.
                </li>
              </ul>
            </div>
            <div className="mb-16 sm:text-left">
              <h2 className="mb-4 text-3xl font-medium text-gray-900">
                Bestseller
              </h2>
              <ul className="list-disc ml-8 text-left">
                <li className="mb-2">
                  Achieve bestseller status to boost visibility.
                </li>
                <li className="mb-2">
                  Gain exposure through prominent placement on online platforms
                  and in bookstores.
                </li>
                <li className="mb-2">
                  Increase sales and recognition for your book.
                </li>
                <li className="mb-2">
                  Establish yourself as a successful author in your genre.
                </li>
              </ul>
            </div>
            <div className="mb-16 ">
              <h2 className="mb-4 text-3xl font-medium text-gray-900">
                Boost Sales
              </h2>
              <ul className="list-disc ml-8 text-left">
                <li className="mb-2">
                  Implement marketing strategies to increase book sales.
                </li>
                <li className="mb-2">
                  Utilize promotions and discounts to attract buyers.
                </li>
                <li className="mb-2">
                  Optimize your book's presentation to appeal to a wider
                  audience.
                </li>
                <li className="mb-2">
                  Maximize profits and reach your financial goals as an author.
                </li>
              </ul>
            </div>
            <div className="relative text-left sm:mr-40 ">
              <p className="text-lg text-left mb-4 mt-20">
                <span className="font-medium text-gray-900">Email:</span>{" "}
                <a className="text" href="mailto:support@kindlebook.com">
                  kdp@grapcodes.com
                </a>
              </p>
              <p className="text-lg">
                <span className="font-medium text-gray-900">Phone:</span>{" "}
                <span>+14046788739</span>
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 w-full lg:mb-0 mb-10">
            <img
              className="object-cover object-center rounded-lg shadow-md"
              alt="hero"
              src="asset/revamxz.png"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Revamz;
