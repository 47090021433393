const Data = [
  {
    image: "asset/profile.jpeg",
    path: "/men/clothing/lengha_choli",
    name: "John Doe",
    comment:
      "Absolutely blown away by the incredible designs and attention to detail! Your company's work is truly top-notch.",
  },
  {
    image: "asset/test.jpeg",
    path: "/women/clothing/women_dress",
    name: "Jane Smith",
    comment:
      "I've never seen such creativity and professionalism combined in one place. Your team's work speaks volumes!",
  },
  {
    image: "asset/profile.2.jpeg",
    path: "/women/clothing/women_dress",
    name: "Alice Johnson",
    comment:
      "Your company's work is simply stunning! It's evident that each project is crafted with passion and expertise. Keep up the fantastic work!",
  },
  {
    image: "asset/profile.3.jpeg",
    path: "/women/clothing/women_saree",
    name: "Michael Brown",
    comment:
      "Your company's work sets the bar high for excellence in design. It's inspiring to see such innovation and dedication come together so seamlessly!",
  },
];

export { Data };
