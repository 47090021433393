import React from "react";
import { Link } from "react-router-dom";

function Collection() {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="text-4xl lg:text-6xl font-bold text-red-950 mb-4 tracking-wide">
            Explore Our Gallery
          </h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="group relative overflow-hidden rounded-lg shadow-lg">
            <Link to="/portfolio">
              <img
                src="asset/real (1).jpeg"
                loading="lazy"
                alt="Graphics"
                className="w-full h-72 object-cover object-center transition-transform duration-300 group-hover:scale-110 hover:shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent opacity-50"></div>
              <span className="absolute bottom-0 left-0 right-0 bg-gray-800 text-gray-200 py-2 px-4 text-sm font-semibold text-center transform -translate-y-full transition-transform duration-300 group-hover:translate-y-0">
                GRAPHICS
              </span>
            </Link>
          </div>
          <div className="group relative overflow-hidden rounded-lg shadow-lg">
            <Link to="/portfolio">
              <img
                src="asset/logog.jpeg"
                loading="lazy"
                alt="Logo"
                className="w-full h-72 object-cover object-center transition-transform duration-300 group-hover:scale-110 hover:shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent opacity-50"></div>
              <span className="absolute bottom-0 left-0 right-0 bg-gray-800 text-gray-200 py-2 px-4 text-sm font-semibold text-center transform -translate-y-full transition-transform duration-300 group-hover:translate-y-0">
                LOGO
              </span>
            </Link>
          </div>
          <div className="group relative overflow-hidden rounded-lg shadow-lg">
            <Link to="/portfolio">
              <img
                src="asset/overlay.jpeg"
                loading="lazy"
                alt="Emotes and Overlays"
                className="w-full h-72 object-cover object-center transition-transform duration-300 group-hover:scale-110 hover:shadow-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent opacity-50"></div>
              <span className="absolute bottom-0 left-0 right-0 bg-gray-800 text-gray-200 py-2 px-4 text-sm font-semibold text-center transform -translate-y-full transition-transform duration-300 group-hover:translate-y-0">
                EMOTES AND OVERLAYS
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collection;
