import React from "react";

function Content() {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:order-2">
            <img
              src="asset/project-4.jpeg"
              loading="lazy"
              alt="Team collaboration"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:order-1">
            <h1 className="text-3xl lg:text-4xl font-bold text-red-950 mb-6">
              Who We Are
            </h1>
            <p className="text-lg text-gray-700 mb-8">
              At Grapecodes, we're more than just a graphic design agency; we're
              your creative partners in success. Our team is a dynamic blend of
              artistic talent and strategic insight, dedicated to transforming
              your brand identity into visual masterpieces that captivate and
              inspire.
            </p>
            <h2 className="text-3xl lg:text-4xl font-bold text-red-950 mb-6">
              Our Competitive Advantage
            </h2>
            <ul className="list-disc text-lg text-gray-700 mb-8">
              <li>
                Innovative Design Thinking: We thrive on pushing the boundaries
                of creativity, constantly exploring new ideas and techniques to
                ensure your brand stands out in a crowded marketplace.
              </li>
              <li>
                Holistic Brand Solutions: Beyond individual design projects, we
                offer comprehensive brand solutions tailored to your unique
                needs, ensuring consistency and coherence across all
                touchpoints.
              </li>
              <li>
                Data-Driven Approach: Our designs are not just visually
                appealing; they're backed by data-driven insights that drive
                engagement, conversion, and brand loyalty.
              </li>
              <li>
                Agile Collaboration: We believe in agile collaboration, working
                closely with you to understand your goals, iterate on ideas, and
                deliver exceptional results on time and within budget.
              </li>
              <li>
                Continuous Innovation: We stay ahead of trends and technologies,
                continuously evolving our skills and methodologies to deliver
                cutting-edge design solutions that keep your brand relevant and
                impactful.
              </li>
              <li>
                Unwavering Commitment: Your satisfaction is our top priority.
                We're committed to exceeding your expectations at every turn,
                providing unparalleled support and service throughout our
                partnership.
              </li>
            </ul>
            <p className="text-lg text-gray-700 mb-8">
              Experience the Grapecodes difference and unlock the full potential
              of your brand's visual identity. Let's embark on a journey of
              creativity and collaboration that leads to unparalleled success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
