// layout/layout.jsx
import React from 'react';
import Navbar from './navbar';
import Footer from './footer';

export default function Layout({ children }) {
  return (
    <div className="app-container">
      <Navbar />
      <div className="content-container">
        {children}
      </div>
      <Footer />
    </div>
  );
}
