import React from "react";
import Logo from "./logo";

function Portfolio() {
  return (
    <div>
    <div className="container mx-auto max-w-3xl px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-red-950 mb-8">
        Our Portfolio
      </h1>
      <div className="bg-gray-100 rounded-lg shadow-md p-6 mb-8">
        <p className="text-lg text-gray-800 leading-7 font-medium">
          Welcome to{" "}
          <span className="text-blue-600 font-semibold">Grapcodes</span>,
          where innovation meets design excellence. At Grapcodes, we're
          passionate about crafting digital experiences that leave a lasting
          impression. With our team of talented designers, developers, and
          strategists, we specialize in delivering cutting-edge solutions
          tailored to your unique needs. Whether you're looking to build a
          stunning website, develop a robust mobile application, or elevate
          your brand identity, Grapcodes has the expertise and creativity to
          bring your vision to life. Our commitment to quality, collaboration,
          and client satisfaction sets us apart in the industry. Join us on a
          journey of innovation and let Grapcodes be your partner in success.
        </p>
      </div>

    </div>
          < Logo />
</div>
  );
}

export default Portfolio;
