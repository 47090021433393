import React from 'react'
import Aboutusinfo from './aboutusinfo'
import Abouthero from './abouthero'

function about() {
  return (
    <div>
        <Abouthero/>
        <Aboutusinfo/>
      
    </div>
  )
}

export default about
