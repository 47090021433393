import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import Portfolio from './pages/portfolio/portfoilo';
import About from './pages/about/about';
import Layout from './layout/layout';
import Contact from './pages/contact/contact';
import Revamz from './pages/revamz/revamz'; // Correct import name

function App() {
  return (
    <div className="App bg-gray-100 min-h-screen font-sans">
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/portfolio" element={<Layout><Portfolio /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/contact" element={<Layout><Contact/></Layout>} />
          <Route path="/revamz" element={<Layout><Revamz/></Layout>} /> {/* Correct usage of Revamz */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
